<template>
  <div style="background-color:transparent" id="Fomulario" align=center>
    <v-card color="transparent" v-if="form.nombre == null" width="400" height="328" elevation="0">
      <LoadingA/>
    </v-card>    
    <v-container 
      style="background-color: transparent" v-show="form.nombre !== null">
      <v-form v-show="showViewInfo === false" v-model="formuValidator" ref="FormularioPago" class="py-2">
        <v-row>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.nombre"
              :label="$t('login.name')+'(s)'"
              :placeholder="$t('login.PutName')"
              outlined
              required
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.apellido"
              :label="$t('login.lastName')+'(s)'"
              :placeholder="$t('login.PutLastName')"
              outlined
              required
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.correo"
              disabled
              :label="$t('login.email')"
              placeholder="example@gmail.com"
              outlined
              required
              :rules="[rules.required, rules.email]"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.celular"
              :label="$t('login.phone')"
              placeholder="000 00 00 000"
              v-mask="'### ## ## ###'"
              outlined
              required
              :rules="[rules.required, rules.phone]"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.calle"
              :label="$t('login.address')"
              :placeholder="$t('login.street')+' ##, ####'"
              outlined
              required
              :rules="[rules.required]"
            />
          </v-col>
          <v-col cols="12" md="6" class="pb-0 mb-n2">
            <v-text-field
              color="var(--color-primary)"
              :dark="$store.state.corpInfo.darkTheme"
              v-model="form.cp"
              :label="$t('login.postalCode')"
              placeholder="#####"
              outlined
              required
              :rules="[rules.required]"
            />
          </v-col>
          <v-row class="px-3 py-2">
            <v-col cols="12" md="4" class="pb-0 mb-n2">
              <v-select
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                v-model="location.pais"
                :items="listOfCountries.countries"
                @change="LoadEstados"
                item-text="name_es"
                item-value="code"
                :label="$t('login.country')"
                persistent-hint
                :rules="[rules.required]"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 mb-n2">
              <v-select
                color="var(--color-primary)"
                :dark="$store.state.corpInfo.darkTheme"
                :disabled="!location.pais"
                v-model="location.estado"
                :items="estados"
                item-text="ciudaddistrito"
                item-value="ciudaddistrito"
                @change="LoadCiudades"
                :label="$t('login.state')"
                persistent-hint
                :rules="[rules.required]"
                outlined
                required
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" class="pb-0 mb-n2">
              <v-text-field
                  color="var(--color-primary)"
                  :dark="$store.state.corpInfo.darkTheme"
                  :disabled="!location.estado"
                  v-model="form.ciudad"
                  :label="$t('login.city')"
                  outlined
                  required
                  :rules="[rules.required]"
                />
              <!-- <v-select
                dark
                v-model="form.ciudad"
                :items="ciudades"
                item-text="ciudadnombre"
                item-value="ciudadnombre"
                :label="$t('login.city')"
                persistent-hint
                :rules="[rules.required]"
                outlined
                required
              ></v-select> -->
            </v-col>
          </v-row>
        </v-row> 
      </v-form>
      <v-card  style="background-color:transparent" v-show="showViewInfo === true" width="400" class="px-2 px-md-4 py-3">
        <v-row align="center" class="d-flex flex-row justify-space-between">
          <v-card-title class="text-subtitle-2 font-weight-bold text-showViewInfo-titles">
            {{$t("actions.information")}}
          </v-card-title>
          <v-btn  text 
                  color="var(--color-primary)" 
                  class="mr-2" 
                  small
                  @click="editInfoUser">
            {{$t("actions.change")}}
          </v-btn>
        </v-row>
        <v-card-text class="pa-0 d-flex flex-row justify-space-between text-showViewInfo-data">
          <v-container>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.name")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.phone")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.email")}} </strong>
            </div>
          </v-container>
          <v-container>
            <div class="text-caption font-weight-normal text-right">
              {{ form.nombre }} {{ form.apellido }}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.celular }}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.correo}}
            </div>
          </v-container>
        </v-card-text>
        
        <v-card-title class="pa-0 text-subtitle-2 font-weight-bold text-showViewInfo-titles">
          {{$t("actions.address")}}
        </v-card-title>
        <v-card-text class="pa-0 d-flex flex-row justify-space-between text-showViewInfo-data">
          <v-container class="pl-3">
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.address")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.postalCode")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.country")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.state")}} </strong>
            </div>
            <div class="text-caption font-weight-normal text-left">
              <strong> {{$t("login.city")}} </strong>
            </div>
          </v-container>
          <v-container class="pr-3">
            <div class="text-caption font-weight-normal text-right">
              {{ form.calle }}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.cp }}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.pais}}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.estado}}
            </div>
            <div class="text-caption font-weight-normal text-right">
              {{ form.ciudad}}
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-container>    
  </div>
</template>

<script>
import LoadingA from "../Tools/LoadingAnimation.vue";
import ListOfCountries from "../../services/ListOfCountries.json";

export default {
  name: "Fomulario",
  components: {
    LoadingA,
  },
  props: ["editInfo"],
  data() {
    return {
      comparisonForm: {},
      flipNumber: 0,
      listOfCountries: {"countries": []},
      terminos: false,
      estados: [],
      ciudades: [],
      formuValidator: true,
      rules: {
        required: (v) => !!v || this.$t("messages.validation.required"),
        email: (v) =>
          /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/.test(v) ||
          this.$t("messages.validation.isEmail"),
        card: (v) =>
          /([\d]{4}) ([\d]{4}) ([\d]{4}) ([\d]{4})/.test(v) ||
          this.$t("messages.validation.validCard"),
        phone: (v) =>
          /([\d]{3}) ([\d]{2}) ([\d]{2}) ([\d]{3})/.test(v) ||
          this.$t("messages.validation.isPhone"),
        exists: (value) => {
          if (typeof value === "string") return this.$t("messages.validation.validLocation");
          else if (value != null && typeof value === "object") {
            if (value.ciudadnombre) return true;
            if (value.ciudaddistrito) return true;
            if (value.code) return true;
            return this.$t("messages.validation.validLocation");
          }
          return true;
        },          
      },
      location: {
        pais: null,
        estado: null,
        ciudad: null
      },
      showViewInfo: false,
      formPreview : {},
      fileEmpty: false,
      form: {}
    };
  },
  watch: {
    editInfo: function(val) {
      if(val === false) {
        this.resetViewData();
        this.showViewInfo = !val;
      }
    }
  },
  created() {
    this.form = {...this.$store.state.auth.userData }
    this.$root.loading = false;
  },
  beforeMount() {
    this.form = {...this.$store.state.auth.userData }
  },
  mounted() {
    this.comparisonForm = {...JSON.stringify(this.form) }
    this.listOfCountries = ListOfCountries;
    this.$refs.FormularioPago.resetValidation();
  },
  methods: {
    loadCityAndState: function () {
      if (this.form.pais !== '') {
        this.LoadEstados();
        if (this.form.estado !== '' && this.form.ciudad !== '') {
          this.LoadCiudades();
        }
      }
      if(this.form.pais === null || this.form.ciudad === null) {
        this.$emit("editUserInfo", 1);
      }
      this.$refs.FormularioPago.resetValidation();
    },
    onAccept(val) {
      this.$refs.CardPreview.onAccept(val);
    },
    Save() {
      if (this.$refs.FormularioPago.validate()) {
        if(this.form.nombre) {
          if (JSON.stringify(this.formPreview) != JSON.stringify(this.form)) {
            this.$root.post("/ServiceClient/UpdateUserData", {
              form : this.form
            }).then((response) => {
              this.fileEmpty = false;
              this.$store.dispatch('auth/updateUserInfo', this.form)
              let info = response.data;
              if (info.success === "TRUE") {
                this.$root.swalAlert(
                  "success",
                  "",
                  this.$t("messages.UpdateInfoUser")
                );
              }
            });
          }
        }        
        this.showViewInfo = true;
        this.$emit("ValidationUserForm", true);
      } else {
        this.showViewInfo = false;
        this.$emit("ValidationUserForm", false);
      }
    },
    LoadEstados(val) {
        this.form.pais = val ? val : this.form.pais;
        let country = ListOfCountries.countries.find(value => value.code == this.form.pais);
        this.location.pais = country;
        
      if(val) {
        this.changeLocation(true);
      }  
      this.$root.post("/ServiceClient/GetEstados", {
        code: this.form.pais
      }).then((response) => {
        let info = response.data;
        if (info.success === "TRUE") {
          this.form.pais = val ? val : this.form.pais;
          this.estados = info.estados;
        }
      });
    },
    LoadCiudades(val) {
      if(val) {
        this.changeLocation(false);  
      } 

      this.form.estado = val ? val : this.form.estado;
      this.location.estado = this.form.estado;
    },
    cityChange(val) {
      this.form.ciudad = val ? val : this.form.ciudad;
      if(!val) {
        this.location.ciudad = this.form.ciudad
      }
    },
    resetViewData() {
      this.form = {...JSON.parse(this.comparisonForm) }
      let country = ListOfCountries.countries.find(value => value.code == this.form.pais);
      this.location.pais = country;
      this.LoadEstados(false)
      this.location.estado = this.form.estado
      this.location.ciudad = this.form.ciudad
    },
    editInfoUser() {
      this.formPreview = {...JSON.parse( JSON.stringify(this.form)) };
      this.$emit("editUserInfo");
      this.showViewInfo = false;
    },
    changeLocation(country) {
      if(country === true) {
        this.location.estado = null;
        this.form.estado = null;
      } 
      this.location.ciudad = null;
      this.form.ciudad = null;
    }
  },
};
</script>

<style scoped>
  .text-showViewInfo-data {
    color: #b4b4b4 !important;
  }
  .text-showViewInfo-titles {
    color: var(--color-primary-light) !important;
  }
</style>