var $
var currentTransaction = {
    processorTransactionId: null,
    id_transac: null,
    referenceID: null,
    transactionTokenId: null
  }
let deviceInformation = null

export const PURCHASE_FUNCTIONS = {
  data() {
    return {
      
    }
  },
  methods: {
    mytokenizadorMixin(form) {
      return new Promise((resolve, reject) => {
        try {
          $ = require('jquery');
          let script = document.createElement("script");

          const sandboxScriptNet = "https://docs.netpay.mx/cdn/js/dev/netpay.min.js";
          const producScriptNet = "https://docs.netpay.mx/cdn/v1.3/netpay.min.js";

          let self = this;

          if(self.$store.state.sites.sandboxNet) script.src = sandboxScriptNet;
          else script.src = producScriptNet;

          // script.src = "https://docs.netpay.mx/cdn/js/dev/netpay.min.154.js";
          // prudcción
          // https://docs.netpay.mx/cdn/v1.3/netpay.min.js

          document.body.appendChild(script);

          script.onload = function () {
            try {
              let script2 = document.createElement("script");
              // test:
              // script2.src = "https://cdn.netpay.mx/js/dev/netpay3ds.js";
              // producción:
              // https://cdn.netpay.mx/js/latest/netpay3ds.js
              const sandbox3DScript = "https://cdn.netpay.mx/js/dev/netpay3ds.js";
              const produc3DScript = "https://cdn.netpay.mx/js/latest/netpay3ds.js";

              if(self.$store.state.sites.sandboxNet) script2.src = sandbox3DScript;
              else script2.src = produc3DScript;

              document.body.appendChild(script2);
              
              script2.onload = function () {
                try {
                  NetPay.setApiKey(self.$store.state.sites.apiKeyNet);
                  NetPay.setSandboxMode(self.$store.state.sites.sandboxNet);
        
                  let deviceFingerPrint
                  function generateDevice(callback) {
                    try {
                      deviceFingerPrint = NetPay.form.generateDeviceFingerPrint();
                      callback().catch(e => {
                        reject(e.message);
                      })
                    } catch(e) {
                      reject(e.message);
                    }
                  }
        
                  generateDevice(async function () {
                    let cardInformation = {
                      cardNumber: form.cardNumber,
                      expMonth: form.expMonth,
                      expYear: form.expYear,
                      cvv2: form.cvv2,
                      vault: true,
                      simpleUse: true,
                      deviceFingerPrint: deviceFingerPrint
                    };
        
                    var validateNumber =  NetPay.card.validateNumber(cardInformation.cardNumber);
                    var validateExpiry = NetPay.card.validateExpiry(cardInformation.expMonth, cardInformation.expYear);
                    var validateCVV = NetPay.card.validateCVV(cardInformation.cvv2, cardInformation.cardNumber);
                    var validateNumberLength = NetPay.card.validateNumberLength(cardInformation.cardNumber);
                    $("#device").val(cardInformation.deviceFingerPrint);
                    if (!validateNumberLength || !validateNumber
                      || !validateExpiry || !validateCVV) {
                      reject({ success: 'FALSE', message: self.$t('sales.netpayErrorMessages.verifyCardDetails')})
                      return false;
                    }
                    NetPay.token.create(cardInformation, success, error);
        
                    function success(e) {
                      let obj = JSON.parse(e.message.data);
                      obj = { ...obj, deviceFingerPrint }
                      resolve(obj)
                    }
                    function error(e) {
                      reject(e.message)
                    }
                    let deviceInformationX = NetPay.form.deviceInformation();
                    deviceInformation = deviceInformationX                      
                  })
                } catch(e) {
                  reject(e.message);
                }
              }
            } catch(e) {
              reject(e.message);
            }
          };
        } catch (e) {
          reject(e.message);
        }
      });
    },
    initTransacTokenMix() {
      this.$root.token = this.$store.getters['auth/getUserS']?.token_cliente

      return new Promise((resolve, reject) => {
        this.$root.post("/ServiceClient/GenerateTransacToken")
        .then((response) => {
          let info = response.data;
          if (info.success === 'TRUE') {
            currentTransaction = {
              processorTransactionId: null,
              id_transac: null,
              referenceID: null,
              transactionTokenId: null
            }
            this.$store.dispatch('shopping/upgradeTransactionToken', info.encoded)
            resolve(info)
          } else {
            this.$store.dispatch('shopping/upgradeTransactionToken', null)
            reject(info)
          }
        })
        .catch(e => {
          reject(e)
        })
      })
    },
    startPurchaseMix(purchaseData, netpayData) {
      try {
        this.$root.loading = true;
        const transacToken = this.$store.state.shopping.transactionToken;
        let _this = this;
        
        netpay3ds.setSandboxMode(_this.$store.state.sites.sandboxNet);

        netpay3ds.init(function () {          
            netpay3ds.config(_this, purchaseData.amount, callback);
        });
        
        const callback = async function (_this, referenceId) {
          currentTransaction["referenceID"] = referenceId;
          netpayData.referenceID = referenceId
          _this.createTransactionMix(purchaseData, netpayData, transacToken)
        }
      } catch (e) {
        this.removeCDN()
        this.$root.swalAlert('error', e.message);
        this.$root.loading = false;
      }      
    },
    async createTransactionMix ( purchaseData, netpayData, transacToken ) {
      try {
        let self = this
        const { referenceID } = netpayData

        self.$root.loading = true;        
        netpayData.deviceInformation = deviceInformation
        netpayData.referenceID = referenceID
        currentTransaction["referenceID"] = referenceID;

        self.$vEntorno.textloading = self.$t('messages.paymentProcess.generateTransaction');
        let clientTransaction = await self.$root.post("/ServiceClient/generateTransaction", purchaseData);
        const response = clientTransaction.data

        if (response.success === 'FALSE') throw new Error(response.message)

        const id_transac = response.id_transac;
        currentTransaction["id_transac"] = id_transac;

        self.makePaymentChargeMix(purchaseData, netpayData, transacToken)

      } catch(e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('sales.failurePaymentMessages.generatingTransaction') , e.message);
      }
    },
    async makePaymentChargeMix( purchaseData, netpayData, transacToken ) {
      try {
        let self = this
        self.$root.loading = true;

        self.$vEntorno.textloading = self.$t('messages.paymentProcess.makeCharge');
        let netpayChargeRes = await self.$root.post("/ServiceClient/generateCharge", {
          tokenTem: transacToken,
          id_corp: self.$root.id_corp,
          netpayData
        });
        
        const response = netpayChargeRes.data
        
        if (response.success === 'FALSE') throw new Error(response.message)

        let netpayResponse = response.data;
        
        currentTransaction["transactionTokenId"] = netpayResponse.transactionTokenId;
        
        if (netpayResponse.status === "review") self.confirmation3D(netpayResponse, transacToken, purchaseData)
        else self.paymentConfirmation(transacToken, purchaseData)

      } catch (e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('messages.paymentErrors.netpayError') , e.message);
      }
    },
    async confirmation3D(netpayResponse, transacToken, purchaseData) {
      try {
        let _this = this
        let self = this
        self.$vEntorno.textloading = self.$t('messages.paymentProcess.validationRequired');
        
        const { status, threeDSecureResponse } = netpayResponse
        const { responseCode, acsUrl, paReq, authenticationTransactionID } = threeDSecureResponse
        netpay3ds.setSandboxMode(self.$store.state.sites.sandboxNet);
        
        let canProceed = await netpay3ds.canProceed(status, responseCode, acsUrl);
        self.$root.loading = false;
        
        if (canProceed) {
          netpay3ds.setSandboxMode(self.$store.state.sites.sandboxNet);

          netpay3ds.proceed( _this, acsUrl, paReq, authenticationTransactionID,
            async (_this, processorTransactionId, status) => {
              if(!_this.$root.idsUsed.includes(processorTransactionId)){
                _this.$root.idsUsed.push(processorTransactionId)

                currentTransaction["processorTransactionId"] = processorTransactionId;

                if (status === 'success') {
                  // response = await confirm(processorTransactionId);
                  _this.validate3DConfirmation(transacToken, purchaseData)
                } else {
                  _this.removeCDN()
                  _this.$root.swalAlert('error', 'No se pudo realizar el pago');
                }                  
              }
            }
          );
        } else {
          // response = await confirm(null);
          self.$root.loading = false;
          self.validate3DConfirmation(transacToken, purchaseData)
        }

        // return response
      } catch (e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('messages.paymentErrors.netpayError') , e.message);
      }
    },
    async validate3DConfirmation( transacToken, purchaseData ) {    
      try {
        let self = this
        self.$root.loading = true;
        self.$vEntorno.textloading = self.$t('messages.paymentProcess.send3dSecure');

        if(!currentTransaction["id_transac"] || !currentTransaction["transactionTokenId"]) throw new Error("Datos inválidos")

        await self.$root.post("/ServiceClient/confirmation3D", {
          tokenTem: transacToken,
          transactionTokenId: currentTransaction.transactionTokenId, 
          processorTransactionId: currentTransaction.processorTransactionId, 
          id_corp : self.$root.id_corp
  
        });

        self.paymentConfirmation( transacToken, purchaseData );
      } catch(e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('messages.paymentErrors.netpayError') , e.message);
      }
    },
    async paymentConfirmation( tokenTem, purchaseData ) {
      try {
        let { event_config, id_event, id_eventd, tickets } = purchaseData;
        let self = this

        if(!currentTransaction["id_transac"] || !currentTransaction["transactionTokenId"]) throw new Error("Datos inválidos")
        
        self.$root.loading = true;
        self.$vEntorno.textloading = self.$t('messages.paymentProcess.verifyPayment');

        let response = await self.$root.post("/ServiceClient/getPaymentDetails", {
          tokenTem,
          transactionTokenId: currentTransaction.transactionTokenId, 
          id_transac: currentTransaction.id_transac,
          id_corp: self.$root.id_corp,
          event_config,
          id_event,
          id_eventd, 
          tickets,
          reserveToken: this.$store.state.shopping.reserveToken
        });
        let info =  response.data;

        self.removeCDN()

        self.$vEntorno.textloading = "Loading...";

        if(info.success === 'FALSE') {
          if(info.statusError === 1) self.$root.swalAlert('error', self.$t('messages.paymentErrors.payError') ,info.message);
          if(info.statusError === 2) self.$root.swalAlert('error', self.$t('messages.paymentErrors.ticketsError') ,info.message);
          if(!info.statusError) self.$root.swalAlert('error', info.message);          
        } else {
          this.$root.showAlertOnPdf = true;
          this.$root.proofPayment(info.info)
        }
        
        self.$root.loading = false;
        this.resetInfo(event_config)
      } catch (e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('messages.paymentErrors.netpayError') , e.message);
      }
    },
    async paymentExclusiveMix(purchaseData, flag) {
      try {
        let self = this
        const transacToken = this.$store.state.shopping.transactionToken;
        let { event_config, id_event, id_eventd, tickets } = purchaseData;

        let response = null, id_transac = null;

        self.$root.loading = true;

        self.$vEntorno.textloading = self.$t('messages.paymentProcess.generateTransaction');
        let clientTransaction = await self.$root.post("/ServiceClient/generateTransaction", purchaseData);
        response = clientTransaction.data

        if (response.success == 'FALSE') {
          self.$root.loading = false;
          return self.$root.swalAlert('error', self.$t('messages.paymentErrors.payError') ,response.message);
        }
        
        id_transac = response.id_transac;
        currentTransaction["id_transac"] = id_transac;

        if(!currentTransaction["id_transac"]) throw new Error("La transacción no se generó correctamente")

        self.$vEntorno.textloading = self.$t('messages.paymentProcess.verifyPayment');

        let getPayment = await self.$root.post("/ServiceClient/getPaymentDetails", {
          freePay: flag,
          tokenTem: transacToken,
          id_transac: currentTransaction.id_transac,
          id_corp: self.$root.id_corp,
          event_config,
          id_event,
          id_eventd, 
          tickets,
          reserveToken: self.$store.state.shopping.reserveToken
        });
        let info = getPayment.data;

        if(info.success === 'FALSE') {
          if(info.statusError === 2) self.$root.swalAlert('error', self.$t('messages.paymentErrors.ticketsError') ,info.message);
          if(!info.statusError) self.$root.swalAlert('error', info.message);          
        } else {
          this.$root.showAlertOnPdf = true;
          this.$root.proofPayment(info.info)
        }
        
        self.$root.loading = false;
        self.resetInfo(event_config)

        // return getPayment
      } catch(e) {
        this.removeCDN()
        this.$root.loading = false;
        this.$root.swalAlert('error', this.$t('messages.paymentErrors.netpayError') , e.message);
      }
    },
    resetInfo(type) {
      this.$store.dispatch('shopping/upgradeTransactionToken', null)
      if(type === 3) this.$store.dispatch('shopping/resetCrossedSell');
      if(type !== 3) this.$store.dispatch('shopping/resetRegularSell');
      this.$store.dispatch('shopping/resetStadisticsValue');
      this.$root.finish();
      currentTransaction = {
        processorTransactionId: null,
        id_transac: null,
        referenceID: null,
        transactionTokenId: null
      } 
      deviceInformation = null
    },
    removeCDN() {
      try {
        let scriptNetpay = 'script[src="https://docs.netpay.mx/cdn/js/dev/netpay.min.js"]'
        let script3DNet = 'script[src="https://cdn.netpay.mx/js/dev/netpay3ds.js"]'

        if(!this.$store.state.sites.sandboxNet) {
          scriptNetpay = 'script[src="https://docs.netpay.mx/cdn/v1.3/netpay.min.js"]'
          script3DNet = 'script[src="https://cdn.netpay.mx/js/latest/netpay3ds.js"]'
        }

        let script = document.querySelector(scriptNetpay);
        let script2 = document.querySelector(script3DNet);
        if (script) {
          script.remove();
        }
        if (script2) {
          script2.remove();
        }
        window.netpay3ds = undefined
        window.NetPay = undefined
        window.$ = undefined

        delete window.netpay3ds
        delete window.NetPay
        delete window.$

        currentTransaction = {
          processorTransactionId: null,
          id_transac: null,
          referenceID: null,
          transactionTokenId: null
        }
        deviceInformation = null 
        this.$store.dispatch('shopping/upgradeTransactionToken', null)
        
        this.$root.finish();
      } catch (error) {        
        this.$root.swalAlert('error', "", error.message);
      }
    },
  }
}