<template>
    <div class="pa-0 ma-0">
        <div class="ma-0 pa-0 d-flex flex-column flex-md-row flex-lg-row justify-space-around align-center">
            <div :class="[{'white--text':$store.state.corpInfo.darkTheme}, {'black--text':!$store.state.corpInfo.darkTheme}]">
                {{ $t('messages.cardDetailsNotice') }}
            </div>
            <div>
                <v-checkbox
                    color="var(--color-primary)"
                    :dark="$store.state.corpInfo.darkTheme"
                    value
                    :label="$t('messages.fillAccountData')"
                    indeterminate
                    @change="fillWithAccountData"
                ></v-checkbox>
            </div>
        </div>
        
        <v-form ref="UserCardForm" class="pa-0">
            <v-row align=center>
                <v-col cols="12" md="6" class="pa-0">
                    <CardComponent 
                        id="cardnumber1" 
                        class="mx-auto"
                        ref="CardComponentPreview" 
                        style="min-width: 240px" 
                        :flipNumber="flipNumber" 
                        :cardOwnerName="cardOwnerName"
                        :noCardChange="cardData.noCard" 
                        :expirationChange="cardData.caducidad"
                        :cvChange="cardData.cv" />
                </v-col>
                <v-col cols="12" md="6" class="pb-0 py-0">
                    <v-row>
                        <v-col cols="12" md="6" lg="6" class="pb-0 py-0">
                            <v-text-field 
                                dense
                                :dark="$store.state.corpInfo.darkTheme"
                                color="var(--color-primary)"
                                v-model="firstName" 
                                :label="$t('login.name')+'(s)'"
                                :placeholder="$t('login.name')+'(s)'"
                                @focus="flipNumber = 0" 
                                @change="removeSpaces"
                                @keyup="fixName"
                                outlined 
                                required
                                :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" md="6" lg="6" class="pb-0 py-0">
                            <v-text-field 
                                dense
                                color="var(--color-primary)"
                                :dark="$store.state.corpInfo.darkTheme"
                                v-model="lastName" 
                                :label="$t('login.lastName')+'(s)'"
                                :placeholder="$t('login.lastName')+'(s)'"
                                @focus="flipNumber = 0" 
                                @change="removeSpaces"
                                @keyup="fixName"
                                outlined 
                                required
                                :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" class="pb-0 py-0">
                            <v-text-field 
                                dense
                                :dark="$store.state.corpInfo.darkTheme"
                                color="var(--color-primary)"
                                v-model="cardData.noCard" 
                                :label="$t('actions.paycard.card')"
                                placeholder="#### ###### ####"
                                v-mask="cardMask" 
                                @focus="flipNumber = 0" 
                                outlined 
                                required
                                :rules="[rules.required, rules.card]" />
                        </v-col>
                        <v-col cols="12" md="6" lg="6" class="pb-0 py-0">
                            <v-text-field 
                                dense
                                :dark="$store.state.corpInfo.darkTheme"
                                color="var(--color-primary)"
                                v-model="cardData.caducidad" 
                                :label="$t('actions.paycard.exp')"
                                placeholder="MM/YY"
                                v-mask="'##/##'" 
                                @focus="flipNumber = 0" 
                                outlined 
                                required 
                                :rules="[rules.required]" />
                        </v-col>
                        <v-col cols="12" md="6" lg="6" class="pb-0 py-0">
                            <v-text-field
                                dense
                                :dark="$store.state.corpInfo.darkTheme"
                                color="var(--color-primary)"
                                autocomplete="off"
                                id="inputCardCv"
                                v-model="cardData.cv" 
                                label="CV" 
                                :placeholder="cvMask" 
                                v-mask="cvMask"
                                :type="showCv ? 'text' : 'password'"
                                @focus="flipNumber = 1" 
                                @change="flipCard"
                                outlined 
                                required 
                                :rules="[rules.required]" 
                                :append-icon="showCv ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showCv = !showCv"/>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4" lg="4">
                    <v-select class="py-0 my-0"
                    :dark="$store.state.corpInfo.darkTheme"
                    color="var(--color-primary)"
                    dense
                    v-model="country"
                    :items="listOfCountries.countries"
                    item-text="name_es"
                    item-value="code"          
                    :label="$t('login.country')"
                    :rules="[rules.required]"
                    @change="LoadEstados"
                    outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-select class="py-0 my-0"
                    :dark="$store.state.corpInfo.darkTheme"
                    color="var(--color-primary)"
                    dense
                    :disabled="!country"
                    v-model="state"
                    :items="estados"
                    :label="$t('login.state')"
                    :rules="[rules.required]"
                    @change="changeLocation(false)"
                    outlined>
                    </v-select>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-text-field class="py-0 my-0"
                    :dark="$store.state.corpInfo.darkTheme"
                    color="var(--color-primary)"
                    dense
                    :disabled="!state"
                    v-model="city"
                    :label="$t('login.city')"
                    outlined
                    required
                    :rules="[rules.required]"
                    />
                </v-col>              
            </v-row>

            <v-row class="my-0 py-0">
                <v-col cols="12" md="8" lg="8">
                    <v-text-field class="py-0 my-0"
                    :dark="$store.state.corpInfo.darkTheme"
                    color="var(--color-primary)"
                    dense
                    v-model="street"
                    :label="$t('login.street')"
                    outlined
                    required
                    :rules="[rules.required]"
                    />
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-text-field class="py-0 my-0"
                    :dark="$store.state.corpInfo.darkTheme"
                    color="var(--color-primary)"
                    dense
                    v-model="postalCode"
                    :label="$t('login.postalCode')"
                    outlined
                    required
                    :rules="[rules.required]"
                    />
                </v-col> 
            </v-row>

            <v-row>
                <v-col cols="12" class="py-0 my-0">
                    <v-checkbox  class="py-0 my-0"
                        :dark="$store.state.corpInfo.darkTheme"
                        color="var(--color-primary)"
                        v-model="terminos" 
                        :rules="[rules.required]">
                        <template v-slot:label>
                            <div>
                                {{$t('actions.paycard.read')}}
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <a  @click.stop="$root.showWarningDialog = true"
                                            v-on="on" 
                                            v-bind="attrs">
                                            {{$t('actions.paycard.terminos')}}
                                        </a>
                                    </template>
                                    {{$t('actions.paycard.terminos')}}
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
import CardComponent from './CardComponent.vue';
import { USER_DATA } from '@/mixins/user-mixin';
import { PURCHASE_FUNCTIONS } from '@/mixins/purchase-mixin';
// import { PURCHASE_SIMULATION_FUNCTIONS } from '@/mixins/purchaseSimulation-mixin';
import ListOfCountries from "../../services/ListOfCountries.json";

export default {
    name: 'UserDataForm',
    components: {
        CardComponent
    },
    mixins: [
        USER_DATA, 
        PURCHASE_FUNCTIONS, 
        // PURCHASE_SIMULATION_FUNCTIONS
    ],
    data() {
        return {
            cardData: {
                noCard: null,
                caducidad: null,
                cv: null
            },

            firstName: "",
            lastName: "",
            phone: null,
            country: null,
            state: null,
            city: null,
            postalCode: null,
            street: null,

            listOfCountries: {"countries": []},
            estados: [],
            flipNumber: 0,
            terminos: false,
            cardOwnerName: "",
            rules: {
                required: (v) => !!v || "Este campo es requerido",
                card: this.getTypeOfCard
            },
            showCv: false,
            brand: false,
            cardMask: "#### #### #### ####",
            cvMask: "###"
        }
    },
    watch: {
        terminos: function () {
            this.flipNumber = 0;
        }
    },
    mounted() {
        this.listOfCountries = ListOfCountries;
        //this.form = this.$FormClient.form;
        this.$refs.UserCardForm.resetValidation();
        this.$refs.UserCardForm.reset();

        const myInput = document.getElementById('inputCardCv');
        myInput.setAttribute('autocomplete', 'off');
    },
    methods: {
        getTypeOfCard(item) {
            if (typeof item == "string") {
                item = item.split(" ").join("");

                if(!item.match(/^5[1-5]\d{14}$/) 
                    && !item.match(/^(4\d{12}(\d{3})?)$/) 
                    && !item.match(/^3[47]\d{13}$/)) {
                    this.brand = false;
                    this.cvMask = "#"
                    return "Tarjeta inválida";
                }

                if (item.match(/^5[1-5]\d{14}$/)) {
                    this.brand = "MASTERCARD";
                    this.cardMask = "#### #### #### ####";
                    this.cvMask = "###"
                    return true;
                }

                if (item.match(/^(4\d{12}(\d{3})?)$/)) {
                    this.brand = "VISA";
                    this.cvMask = "###"
                    this.cardMask = "#### #### #### ####";
                    return true
                }

                if (item.match(/^3[47]\d{13}$/)) {
                    this.brand = "AMEX";
                    this.cardMask = "#### ###### #####";
                    this.cvMask = "####"
                    return true;
                }
            } else {
                this.brand = false;
                return "Tarjeta inválida";
            }
        },
        ValidateCard() {
            let self = this;
            this.showCv = false;
            
            if (this.$refs.UserCardForm.validate()) {
                let userCardData = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    phone: null,
                    country: this.country,
                    state: this.state,
                    city: this.city,
                    postalCode: this.postalCode,
                    street: this.street
                }
                self.$root.loading = true;
                let dateExpi = this.cardData.caducidad.split("/");
                let newForm = {
                    cardNumber: this.cardData.noCard.replaceAll(" ", ""),
                    expMonth: dateExpi[0],
                    expYear: dateExpi[1],
                    cvv2: this.cardData.cv,
                };                
                this.mytokenizadorMixin(newForm)
                .then(
                    (response) => {
                        if (response.token) {
                            self.$root.Toast("success", self.$t("messages.tarjetValid")+"!", "");
                            self.$emit("validationCardSuccessful", response, userCardData);
                        } else {
                            self.$root.swalAlert("error", null, self.$t("sales.netpayErrorMessages.invalidCard"));
                            self.$emit("validationCardDenied");
                        }
                    }, 
                    (e) => {
                        self.$root.swalAlert("error", self.$t("sales.netpayErrorMessages.netpayProblem"), e.message);
                        self.$emit("validationCardDenied");
                    })
                .catch(e => {
                    self.$root.swalAlert("error", self.$t("sales.netpayErrorMessages.netpayProblem"), e.message);
                    self.$emit("validationCardDenied");
                })
            } else {                
                if(!this.terminos) this.$root.swalAlert("warning", "", this.$t("messages.acceptYermsConditions"));
                
                if(this.terminos) this.$root.swalAlert("warning", "", this.$t("messages.checkTheFormData"));
                
                self.$emit("validationCardDenied");
            }
        },
        onAccept(val) {
            this.$refs.CardComponentPreview.onAccept(val);
        },
        flipCard() {
            this.flipNumber = 0;
        },
        changeLocation(country) {
            if(country === true) {
                this.state = null;
            } 
            this.city = null;
        },
        LoadEstados(val) {
            this.changeLocation(true);
            this.$root.post("/ServiceClient/GetEstados", {
                code: val
            }).then((response) => {
                let info = response.data;
                if (info.success === "TRUE") {
                    this.estados = info.estados;
                }
            });
        },
        fixName() {
            this.cardOwnerName = (this.firstName || "") + " " + (this.lastName || "")
        },
        removeSpaces(value) {
            value = value.trim();
            return value
        },
        fillWithAccountData(value) {
            if(value) {                
                this.firstName = this.$store.state.auth.userData.nombre
                this.lastName = this.$store.state.auth.userData.apellido
                this.phone = null
                this.country = this.$store.state.auth.userData.pais
                this.LoadEstados(this.country)
                this.state = this.$store.state.auth.userData.estado
                this.city = this.$store.state.auth.userData.ciudad
                this.postalCode = this.$store.state.auth.userData.cp
                this.street = this.$store.state.auth.userData.calle
                
            } else {
                this.firstName = null
                this.lastName = null
                this.phone = null
                this.country = null
                this.state = null
                this.city = null
                this.postalCode = null
                this.street = null
            }
        }
    }
}
</script>